<template>
  <div class="page">
    <div class="page__title">
      {{ getModuleConfig("exchange_records_page.form_title") }}
    </div>
    <div
      class="page__desc"
      v-html="getModuleConfig('exchange_records_page.form_desc')"
    ></div>
    <div  class="page__content" v-if="getModuleConfig('exchange_records_page.content')" v-html="getModuleConfig('exchange_records_page.content')"></div>
    <div class="pointes-list">
      <PointCard
        v-for="item in pointLogs"
        :key="item.id"
        :item="item"
        isRedeemed
      ></PointCard>
      <PointPagination v-model="page" :totalPage="totalPage"></PointPagination>
    </div>
  </div>
</template>

<script>
import PointCard from "@/pages/Dashboard/Point/PointCard";
import PointPagination from "@/pages/Liff/Point/PointPagination";
import moment from "moment";
import pointApi from '@/apis/liff/v2/point'
import pointMixin from "@/mixins/liff/point";

export default {
  mixins: [pointMixin],
  components: {
    PointCard,
    PointPagination,
  },

  data() {
    return {
      pointLogs: [],
      popup: {
        point: 0,
      },
      eventCode: this.$route.query.event ?? null,
      page: 1,
      totalPage: 10,
    };
  },

  mounted() {
    this.fetchRedeemLogs()
  },

  watch: {
    page() {
      this.fetchRedeemLogs()
    }
  },

  methods: {
    async fetchRedeemLogs() {
      let res = await pointApi.listRedeemLogs(this.eventCode, this.page)
      this.totalPage = res.meta.last_page;
      let point_logs = res.data;

      if (!point_logs || !point_logs.length) {
        this.pointLogs = [];
        return;
      }

      this.pointLogs = point_logs.map((pl) => ({
        id: pl.id,
        name: pl.gift?.name,
        subtitle: pl.gift?.data?.sub_title || "",
        point: pl.gift?.point_need,
        date: moment(pl.created_at).format("YYYY/MM/DD"),
        image: pl.gift?.image_url,
        status: "redeemed",
      }));
    },
  },
};
</script>

<style lang="scss">
@import '@/assets/scss/shared/components/_fields.scss';

.page {
  padding: 16px 12px;
  background: var(--liff-content_bg_color);
  &__title {
    font-size: 20px;
    line-height: 28px;
    font-weight: 500;
    margin-top: 10px;
    margin-bottom: 10px;
    text-align: center;
  }
  &__desc {
    font-size: 14px;
    line-height: 22px;
    color: var(--liff-secondary_text_color);
    margin-top: 10px;
    margin-bottom: 10px;
    text-align: center;
    b {
      color: var(--s-primary);
      font-weight: 600;
    }
  }
  &__content {
    margin: 10px 20px;
  }
}
</style>
